<template>
 <div class = 'main' @click = 'googleSearch'>
   <div class = 'title'>
      <h1>{{quote}}</h1>
      <p>{{author}}</p>
   </div>
  
 </div>
</template>

<script>


export default {
  name: 'App',
  data() {
    return {
      quote: '',
      author: '',
      counter: 0,
    }
  },
  mounted() {
    this.getQuote();
  },
  methods: {
    getQuote(){
      fetch('https://api.quotable.io/random?maxLength=100&tags=technology')
      .then(response => response.json())
      .then(data => {
        this.quote = (data.content);
        this.author = (data.author);
      })
      
    },
    googleSearch(){
      if(this.counter===2) window.location.replace('https://www.google.com/search?q='+'DAYS+UNTIL+21%2F11%2F22');
      else this.counter++;
    
      window.open('https://www.google.com/search?q="' + this.quote+'"');
      if (this.counter!==1){
        this.getQuote();
      } else {
        this.quote = '3,FOUR,V';
        this.author='21.11.22'
      }
      this.counter++;
    }
   
  },

}
</script>

<style>

@import url('https://fonts.googleapis.com/css2?family=Press+Start+2P&display=swap');
*{
  background: black;
  color: whitesmoke;
}
#app {
  font-family: 'Press Start 2P';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: whitesmoke;
  background: transparent;
  margin-top: 60px;
}
.main{
  cursor:pointer;
  position: absolute;
left: 50%;
top: 50%;
transform: translate(-50%, -50%);
border: 5px solid #ffffff;
padding: 50px;
box-shadow: #ffffff 20px 20px 0px;
transition: all 0.5s ease-in-out;
}

.main:hover{
  box-shadow: #ffffff 30px 30px 0px;
}

</style>
